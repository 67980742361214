<template>
  <div style="overflow: hidden; height: calc(100vh - 135px);position: relative;">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
   
    <img :src="output" />
    <panZoom :options="{
      minZoom: 0.1,
      maxZoom: 2,
      bound: true,
    }" @init="onInit" @onTouch="onTouch">
      <div ref="visualContainer" style="width: 1980px;">
        <canvas id="canvas"></canvas>
        <v-row>
          <v-col cols="2">
            <div v-for="competence in competencies" :key="competence.id">
              <v-sheet style="cursor: pointer" :elevation="
                selectedEduProgCompetence && selectedEduProgCompetence === competence.id
                  ? 12
                  : 0
              " v-bind:class="{
                'grey lighten-2 grey--text':
                  selectedEduProgCompetence && selectedEduProgCompetence !== competence.id,
              }" @click="connectOne(competence.id)" @mouseenter="connectAll(competence.id)" @mouseleave="connectAll()"
                :ref="'competence' + competence.id" outlined rounded class="pa-3 ma-2">
                <b>{{ competence.competence.type }}<span v-if="competence.competence.code"> {{ competence.competence.code }}</span>: </b> {{ competence.competence.name }}
              </v-sheet>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row>
              <v-col cols="6">
                <div v-for="competence in competencies" :key="competence.id">
                  <div v-if="
                    !selectedEduProgCompetence ||
                    selectedEduProgCompetence === competence.id
                  ">
                    <v-row v-for="customSkill in competence.customSkills" :key="customSkill.id">
                      <v-col>
                        <v-sheet rounded outlined :ref="'custom' + customSkill.id" elevation="3" style="cursor: pointer"
                          v-bind:class="
                            customSkill.type == 'skill' ? 'green' : 'blue'
                          " class="blue lighten-3 pa-1 ma-1 text-body-2">
                          <v-row>
                            <v-col class="py-0">

                              <b v-if="customSkill.type == 'skill'">У:</b><b v-else>З:</b> {{
                              customSkill.name
                              }}
                            </v-col>
                            <v-col cols="auto" class="py-0">
                              <v-btn @click="
      customForm = { ...customSkill } ;
    customModal = true;
                              " color="primary" icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <deleteBtn title="Удалить" text="Вы действительно хотите удалить этот элемент?"
                                :actionHref="'competencies/custom-delete?id=' + customSkill.id"
                                @remove="customRemove(competence.id, customSkill.id)" />
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <disciplineSkillSelect type="customSkill" :eduProgId="eduProgId"
                          :skillKnowledgeId="customSkill.id" :disciplines.sync="disciplines"
                          :competenceId="selectedEduProgCompetence" :competencies="competencies"/>
                      </v-col>
                    </v-row>

                  </div>
                </div>

              </v-col>

            </v-row>
            <v-row>
              <v-col cols="3">
                <div v-if="selectedEduProgCompetence">
                  <v-sheet rounded outlined ref="customAdd" elevation="3" style="cursor: pointer"
                    class="pa-3 ma-1 text-body-2">
                    <v-btn block @click="
  customForm = { eduProgCompetenceId: selectedEduProgCompetence };
customModal = true;
                    " color="primary">
                      <v-icon>mdi-plus</v-icon>Добавить умение/знание
                    </v-btn>
                  </v-sheet>
                </div>
              </v-col>
            </v-row>
            <div v-for="workFunction in filteredWorkFunctions" :key="workFunction.id">
              <v-row >
                <v-col cols="2" class="d-flex align-center py-0">
                  <v-alert :elevation="
                  selectedWorkFunction && selectedWorkFunction === workFunction.id
                  ? 12
                  : 0
              " v-bind:class="{
                'grey lighten-2 grey--text':
                !selectedWorkFunction || selectedWorkFunction !== workFunction.id,
              }"
               @click="selectWorkFunction(workFunction.id)" :ref="'workFunction' + workFunction.id" style="cursor: pointer; position: sticky; top: 0px; z-index: 1"
                    class="py-1 px-3 ma-0 my-1" border="left" dense colored-border color="deep-purple accent-4"
                    >
                    <div class="text-subtitle-2">
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <v-avatar v-bind="attrs" v-on="on" :style="'background-color: ' + $colorsArray[workFunction.profStandIndex] + ' ;' " size="20" class="mr-2"/>
                            </template>
                            {{ workFunction.profStandName }}
                        </v-tooltip>
                      <b>ТФ:</b>
                      {{ workFunction.name }}
                      <div class="text-right"><comment  :comment="getComment('workFunction',workFunction)" /></div>
                      
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col>
                  <div v-for="workAction in workFunction.workActions" :key="workAction.id">
                    <v-row v-if="selectedWorkFunction==workFunction.id">
                      <v-col cols="3" class="d-flex align-center py-0">
                        <v-alert :ref="'action' + workAction.id" class="py-1 px-3 ma-0 my-1" border="left" dense
                          colored-border color="orange accent-4" elevation="3">
                          <div class="text-subtitle-2">
                            <b>ТД:</b>
                            {{ workAction.name }}
                            <div class="text-right"><comment  :comment="getComment('workAction',workAction)" /></div>
                          </div>
                        </v-alert>
                      </v-col>
                      <v-col cols="1"></v-col>
                      <v-col class="py-0" cols="8">
                        <v-row v-for="skill in workAction.skills" :key="skill.id">
                          <v-col >
                            <v-sheet :ref="'skill' + skill.id" rounded outlined elevation="3" style="cursor: pointer"
                              class=" lighten-3 pa-1 ma-1 text-body-2" :class="skill.type=='um'?'green':'blue'">
                              <b v-if="skill.type=='um'">У:</b><b v-else>З:</b> {{ skill.name }}
                            </v-sheet>

                          </v-col>
                          <v-col cols="auto">
                            <disciplineSkillSelect type="skill" :eduProgId="eduProgId" :workFunctionId="workFunction.id" :competenceId="selectedEduProgCompetence"
                              :skillKnowledgeId="skill.id" :disciplines.sync="disciplines" :competencies="competencies"/>
                          </v-col>

                        </v-row>

                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </panZoom>
    <v-dialog v-model="customModal" width="500px">
      <v-card>
        <v-toolbar color="#365d84" flat dark style="max-height: 64px">
          <v-toolbar-title>Добавить умение\знание</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="customModal = false" light>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select label="Тип" :error="customErrors.type ? true : false"
            :error-messages="customErrors.type ? customErrors.type : []" v-model="customForm.type" :items="[
              { text: 'Знание', value: 'knowledge' },
              { text: 'Умение', value: 'skill' },
            ]">
          </v-select>
          <v-text-field label="Название" v-model="customForm.name"></v-text-field>
          <v-btn @click="customSave()" :loading="customSaving">Сохранить</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import deleteBtn from '../../delete.vue'
import disciplineSkillSelect from './disciplineSkillSelect.vue';
import comment from '@/components/eduProgProfStandards/workFunctions/comment'
export default {
  props: ["eduProgId"],
  data() {
    return {
      loading: true,
      dialog: false,
      ctx: null,
      canvas: null,
      selectedEduProgCompetence: false,
      selectedWorkFunction:null,
      workFunctions: [],
      competencies: [],
      connection: [],
      disciplines: [],
      output: null,
      customModal: false,
      customForm: {},
      customErrors: {},
      customSaving: false,
      comments:[]
    };
  },
  components: {
    deleteBtn,
    disciplineSkillSelect,
    comment
  },
  computed: {
    filteredWorkFunctions() {
      if (this.selectedEduProgCompetence) {
        let workFunc = JSON.parse(JSON.stringify(this.workFunctions));
        let competence = this.competencies.find(el => el.id === this.selectedEduProgCompetence)
        console.log(competence)
        workFunc = workFunc.filter(
          (el) =>
            competence.workFunctions.findIndex(wf => wf == el.id) !== -1
        );
        return workFunc;
      } else {
        return this.workFunctions;
      }
    },
  },
  destroyed() { },
  mounted() {
    this.redraw()
  },
  methods: {
    getComment(type,target){
            let comment = this.comments.find(el=>el.targetId==target.id && type==type)
            if(!comment){
                comment={text:'',targetId:target.id}
            }
            return comment
        },
    selectWorkFunction(id){
      if(this.selectedWorkFunction==id){
        this.selectedWorkFunction=null
      } else {
        this.selectedWorkFunction=id
      }
      this.$nextTick(()=>{
        this.connectAll();
      })
      
    },
    customSave() {
      this.customSaving = true;
      if (!this.customForm.id) {
        this.$http
          .post(
            "competencies/custom-add?eduProgCompetenceId=" + this.customForm.eduProgCompetenceId,
            this.customForm
          )
          .then((response) => {
            this.competencies
              .find((el) => el.id == response.data.eduProgCompetenceId)
              .customSkills.push(response.data);
            this.$nextTick(() => {
              this.connectAll();
            });
            this.customModal = false;
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.errors) {
              this.customErrors = e.response.data.errors;
            } else {
              console.log(e)
              alert("Ошибка!");
            }
          })
          .finally(() => {
            this.customSaving = false;
          });
      } else {
        this.$http
          .post(
            "competencies/custom-update?id=" + this.customForm.id,
            this.customForm
          )
          .then((response) => {
            let competence = this.competencies.find((el) => el.id === response.data.eduProgCompetenceId)
            let customIndex = competence.customSkills.findIndex(el => el.id === response.data.id)
            this.$set(competence.customSkills, customIndex, response.data)

            this.$nextTick(() => {
              this.connectAll();
            });
            this.customModal = false;
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.errors) {
              this.customErrors = e.response.data.errors;
            } else {
              alert("Ошибка!");
            }
          })
          .finally(() => {
            this.customSaving = false;
          });
      }

    },
    customRemove(competenceId, customId) {
      let competence = this.competencies.find((el) => el.id === competenceId)
      let customIndex = competence.customSkills.findIndex(el => el.id === customId)
      competence.customSkills.splice(customIndex, 1)
      this.$nextTick(() => {
        this.connectAll();
      });
    },
    async print() {
      const el = this.$refs.visualContainer;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        windowWidth: el.scrollWidth,
        windowHeight: el.scrollHeight,
      };
      this.output = await this.$html2canvas(el, options);
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("connection/competencies-work-function-visual", {
          params: {
            eduProgId: this.eduProgId,
          },
        })
        .then((response) => {
          this.workFunctions = response.data.workFunctions;
          this.competencies = response.data.competencies;
          this.connection = response.data.connection;
          this.disciplines = response.data.disciplines;
          this.comments=response.data.comments
          this.selectedEduProgCompetence = this.competencies[0].id
          this.selectedWorkFunction =  this.competencies[0].workFunctions[0]
          console.log(this.selectedDiscipline)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.connectAll();
          })
          this.loading = false;
        });
    },
    onInit: function (panzoomInstance) {
      let that = this;
      panzoomInstance.setTransformOrigin(null);
      panzoomInstance.moveTo(0, 0);
      panzoomInstance.on("panstart", function () {
        that.isDragging = true;
        // Note: e === instance.
      });
      panzoomInstance.on("panend", function () {
        that.isDragging = false;
        // Note: e === instance.
      });
    },

    onTouch: function () {
      // `e` - is current touch event.

      return false; // tells the library to not preventDefault.
    },
    redraw() {
      this.canvas = document.getElementById("canvas");
      this.ctx = this.canvas.getContext("2d");
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    connectOne(competenceId) {
      if (this.selectedEduProgCompetence === competenceId) {
        //this.selectedEduProgCompetence = false;
      } else {
        this.selectedEduProgCompetence = competenceId;
      }

      this.$nextTick(() => {
        this.connectAll(competenceId);
      });
    },
    connect(ref1, ref2, color = "grey", width = 1) {
      this.drawLine(ref1, ref2, "left", color, width);
    },
    connectAll(competenceId) {
      let rect = this.getOffset(this.$refs.visualContainer);
      this.canvas.width = rect.width;
      this.canvas.height = rect.height;
      this.ctx.clearRect(0, 0, rect.width, rect.height);
      let color = "grey";
      let width = 1;
      let competencies = JSON.parse(JSON.stringify(this.competencies));
      let selectedFunctions = []
      if (this.selectedEduProgCompetence) {
        competencies = competencies.filter(
          (el) => el.id === this.selectedEduProgCompetence
        );
      }
      for (let comp of competencies) {
        if (comp.id == competenceId) {
          color = null;
          width = 2;
        } else {
          color = "grey";
          width = 1;
        }
        for (let workFuncId of comp.workFunctions) {
          if (competenceId == comp.id) {
            selectedFunctions.push(workFuncId)
          }
          this.drawLine(
            this.$refs["competence" + comp.id][0].$el,
            this.$refs["workFunction" + workFuncId][0].$el,
            "right",
            color,
            width
          );
        }
        if (this.selectedEduProgCompetence) {
          this.drawLine(
            this.$refs["competence" + comp.id][0].$el,
            this.$refs["customAdd"].$el,
            "right",
            color,
            width
          );
        }
        for (let customSkill of comp.customSkills) {
          this.drawLine(
            this.$refs["competence" + comp.id][0].$el,
            this.$refs["custom" + customSkill.id][0].$el,
            "right",
            color,
            width
          );
        }
      }


      for (let workFunc of this.filteredWorkFunctions) {
        if(this.selectedWorkFunction!=workFunc.id){
          continue;
        }
        let color = "grey";
        let width = 1;
        if (
          selectedFunctions.findIndex((el) => el == workFunc.id) !== -1
        ) {
          color = null;
          width = 2;
        } else {
          color = "grey";
          width = 1;
        }
        for (let action of workFunc.workActions) {
          this.drawLine(
            this.$refs["workFunction" + workFunc.id][0].$el,
            this.$refs["action" + action.id][0].$el,
            "right",
            color,
            width
          );
          for (let skill of action.skills) {
            this.drawLine(
              this.$refs["action" + action.id][0].$el,
              this.$refs["skill" + skill.id][0].$el,
              "right",
              color,
              width
            );
          }
        }
      }
    },
    drawLine(el1, el2, direction, color, width) {
      if (!color) {
        var ramp = this.ctx.createLinearGradient(0, 0, this.canvas.width, 0);
        ramp.addColorStop("0", "blue");
        ramp.addColorStop("0.8", "magenta");
        ramp.addColorStop("1", "red");
        this.ctx.strokeStyle = ramp;
      } else {
        this.ctx.strokeStyle = color;
      }

      this.ctx.lineWidth = width;
      let offset1 = this.getOffset(el1);
      let offset2 = this.getOffset(el2);

      this.ctx.beginPath();

      let fromX, fromY, toX, toY;
      if (direction == "right") {
        fromX = offset1.left + offset1.width;
        fromY = offset1.top + offset1.height / 2;
        toX = offset2.left - 5;
        toY = offset2.top + offset2.height / 2;
      } else {
        fromX = offset1.left;
        fromY = offset1.top + offset1.height / 2;
        toX = offset2.left + offset2.width;
        toY = offset2.top + offset2.height / 2;
      }
      this.ctx.moveTo(fromX, fromY);
      this.ctx.lineTo(toX, toY);
      this.ctx.stroke();

      var headlen = 20; // length of head in pixels
      var dx = toX - fromX;
      var dy = toY - fromY;
      var angle = Math.atan2(dy, dx);
      this.ctx.moveTo(fromX, fromY);
      this.ctx.lineTo(toX, toY);
      this.ctx.lineTo(
        toX - headlen * Math.cos(angle - Math.PI / 6),
        toY - headlen * Math.sin(angle - Math.PI / 6)
      );
      this.ctx.moveTo(toX, toY);
      this.ctx.lineTo(
        toX - headlen * Math.cos(angle + Math.PI / 6),
        toY - headlen * Math.sin(angle + Math.PI / 6)
      );
      this.ctx.stroke();
    },
    getOffset(el) {
      return {
        left: el.offsetLeft,
        top: el.offsetTop,
        width: el.offsetWidth,
        height: el.offsetHeight,
      };
    },
  },
};
</script>

<style>
.wrapperDisabled {
  pointer-events: none;
}

canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  box-sizing: border-box;
  pointer-events: none;
}
</style>
