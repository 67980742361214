<template>
  <v-dialog v-model="dialog" fullscreen scrollable persistent :retain-focus="false">
    <v-card >
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title
          >Матрица компетенций</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>

        <v-tabs v-model="tab" class="flex-grow-0">
          <v-tab>Матрица компетенций </v-tab>
          <v-tab>Матрица трудовых функций</v-tab>
          <v-tab>Матрица квалификаций</v-tab>
        </v-tabs>
        <v-divider/>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-lazy>
              <competenciesMatrix :eduProgId="eduProgId" />
            </v-lazy>
          </v-tab-item>

          <v-tab-item>
            <v-lazy>
              <workFunctionsMatrix :eduProgId="eduProgId"/>
            </v-lazy>
          </v-tab-item>
          <v-tab-item>
            <v-lazy>
              <qualificationMatrix :eduProgId="eduProgId"/>
            </v-lazy>
          </v-tab-item>
        </v-tabs-items>

    </v-card>
  </v-dialog>
</template>
<script>
import competenciesMatrix from './competenciesMatrix.vue'
import workFunctionsMatrix from './workFunctionsMatrix.vue'
import qualificationMatrix from './qualificationMatrix.vue'
export default {
  props: ["eduProgId"],
  data() {
    return {
      tab: 0,
      dialog: false,
    };
  },
  components:{
    competenciesMatrix,
    workFunctionsMatrix,
    qualificationMatrix
  },
  methods: {
  }
};
</script>
