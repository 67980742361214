<template>
  <v-autocomplete :loading="usersLoading" clearable :filter="function(){return true}" :items="users" v-model="inputVal"
    :search-input.sync="search" item-value="user_id" :return-object="retutnObject" label="Поиск пользователя..."
    :rules="rules" no-data-text="Ничего не найдено">
    <template v-slot:selection="{ attr, on, item, selected }">
        <div
          v-bind="attr"
          :input-value="selected"
          v-on="on"
        >
        <v-img v-if="item.avatar" :src="$http.defaults.apiURL + item.avatar"></v-img>
        <v-icon v-else size="48px">mdi-account-circle</v-icon>
          {{item.surname}} {{item.name}} {{item.patronymic}}
        </div>
      </template>

    <template v-slot:item="{ item }">
      <v-list-item-avatar
        >
        <v-img v-if="item.avatar" :src="$http.defaults.apiURL + item.avatar"></v-img>
        <v-icon v-else size="48px">mdi-account-circle</v-icon>
        </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{item.surname}} {{item.name}} {{item.patronymic}}</v-list-item-title>
        <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
      </v-list-item-content>
    </template>

  </v-autocomplete>
</template>
<script>
export default {
  name: "index",
  props: ['rules', 'value', 'retutnObject'],
  data: () => ({
    users: [],
    usersLoading: false,
    search: null
  }),
  watch: {
    search() {
      // Items have already been loaded
      //if (this.permissionUsers.length > 0) return
      // Items have already been requested
      if (this.usersLoading || !this.search)
        return
      this.usersLoading = true
      this.users = []
      // Lazily load input items
      this.$http.get('user/user-search?q=' + this.search)
        .then(res => {
          this.$set(this, 'users', res.data)
          // this.permissionUsers = res.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.usersLoading = false))
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
        console.log(this.inputVal)
      }
    }
  }
};
</script>