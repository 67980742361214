<template>
  <v-menu v-model="dialog" max-width="500" :close-on-click="false" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
      class="mr-3"
        bordered
        light
        color="green"
        offset-x="15"
        offset-y="15"
        :content="String(count)"

      >
        <v-btn
        v-bind="attrs"
          v-on="on"
          class="white--text"
          color="primary"
          depressed
        >
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
      </v-badge>
    </template>


    <v-card elevation="12"  >
      <v-toolbar color="#365d84" flat dark>
        <v-toolbar-title>Методисты</v-toolbar-title> 
        <v-spacer class="mx-5"></v-spacer
        >
        <addUserToGroup :eduOrgId="eduOrgId" :type="type" @roleAdd="roles.push($event)"/>
        <v-btn @click="dialog=false" light class="ml-3">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-if="!loading">
       
        <v-list v-if="roleList.length>0">
          <v-list-item two-line v-for="role in roleList" :key="role.id">
            <v-list-item-content>
              <v-list-item-title>{{role.user.lastname}} {{role.user.name}} {{role.user.patronymic}}</v-list-item-title>
              <v-list-item-subtitle>{{role.user.email}}</v-list-item-subtitle>
              <v-chip color="red" v-if="!role.user.confirmed_at" dark class="text-center" style="max-width:138px">Не подтверждён</v-chip>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="remove(role)" :loading="role.deleting" icon
                ><v-icon color="red">mdi-delete</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
        
        <div v-else class="text-center text-h6">Пусто</div>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader type="list-item-two-line@1"></v-skeleton-loader>
      </v-card-text>
    </v-card>

  </v-menu>
  
</template>

<script>
import addUserToGroup from "./addUserToGroup.vue"
export default {
  props:['type','count','eduOrgId'],
  data: () => ({
    tab: 0,
    addDialog: false,
    form: {},
    roles:[],
    loading:false,
    dialog:false
  }),
  watch:{
    dialog(val){
      if(val===true){
        this.fetchData()
      }
    }
  },
  computed:{
    control(){
      return this.$checkRole(this.$route.params.id,'admin')
    },
    roleList(){
      return this.roles.filter(el=>el.role===this.type)
    }
  },
  components: {
    addUserToGroup
  },
  methods: {
    fetchData() {
      this.loading=true
      this.$http
        .get("edu-prog/roles?eduProgId="+this.$route.params.id+'&type='+this.type)
        .then((response) => {
          this.roles = response.data;
        })
        .catch(() => {
          alert("Возникла ошибка");
        }).finally(()=>{
          this.loading=false
        });
    },
    remove(item) {
      let index = this.roles.findIndex(el=>el.id===item.id)
      this.$set(this.roles[index],'deleting',true)
      this.$http.get('edu-org/remove-role',{
        params:{
          orgId: this.eduOrgId,
          roleId: item.id,
          type: this.type
        }
      }).then(()=>{
        this.roles.splice(index, 1);
      }).catch(()=>{
          this.$set(this.roles[index],'deleting',false)
          alert('Ошибка')
      })
    },
  },
};
</script>

<style>
</style>