<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-circle-edit-outline</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2 mt-3 justify-space-between">
        <span v-if="!eduProg || !eduProg.id">Новая организация</span
        ><span v-else>Изменить</span>
        <v-btn fab @click="dialog = false" small
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form ref="form" class="mt-3">
          <v-text-field
            v-model="form.name"
            :error="errors.name ? true : false"
            :error-messages="errors.name ? errors.name : []"
            outlined
            :counter="255"
            :rules="rules.required"
            label="Наименование"
            required
          ></v-text-field>

          <v-text-field
            v-model="form.profile"
            :error="errors.profile ? true : false"
            :error-messages="errors.profile ? errors.profile : []"
            outlined
            :counter="255"
            :rules="rules.required"
            label="Профиль"
            required
          ></v-text-field>

          <v-text-field
            type="number"
            v-model="form.term"
            :error="errors.term ? true : false"
            :error-messages="errors.term ? errors.term : []"
            outlined
            :rules="rules.required"
            label="Нормативный срок освоения, утвержденный образовательной организацией (в часах)"
            required
          ></v-text-field>

          <v-select
            :items="[
              { label: 'Очная', value: 1 },
              { label: 'Очно-заочная', value: 2 },
              { label: 'Заочная', value: 3 },
            ]"
            v-model="form.studyForm"
            :error="errors.studyForm ? true : false"
            :error-messages="errors.studyForm ? errors.studyForm : []"
            outlined
            item-text="label"
            item-value="value"
            label="Уровень образования"
          ></v-select>

          <v-autocomplete v-if="form.type=='vo' || form.type=='spo'"
            :items="oksoList"
            :loading="oksoLoading"
            :search-input.sync="oksoSearch"
            hide-no-data
            filled
            hide-selected
            item-text="name"
            item-value="id"
            v-model="form.oksoId"
            :error="errors.oksoId ? true : false"
            :error-messages="errors.oksoId ? errors.oksoId : []"
            label="Код по ОКСО"
          >
            <template v-slot:selection="data">
              {{ data.item.code }} /
              <b class="pl-1">{{ data.item.name }}</b>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.code"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="saving"
          :loading="saving"
          @click="save()"
          >Сохранить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["eduProg"],
  data() {
    return {
      dialog: false,
      saving: false,
      oksoSearch: "",
      oksoLoading: false,
      oksoList: [],
      form: {},
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
      errors: {},
    };
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.form = { ...this.eduProg };
      }
    },
    oksoSearch(val) {
      // Items have already been requested
      if (this.oksoLoading) return;

      this.oksoLoading = true;

      this.$http
        .get("edu-prog/okso-list", {
          params: {
            string: val,
          },
        })
        .then((response) => {
          this.oksoList = response.data.list;
        })
        .catch(() => {
          alert("Ошибка при загрзке списка кодов по ОКСО");
        })
        .finally(() => {
          this.oksoLoading = false;
        });
    },
  },
  methods: {
    save() {
      this.$set(this, "saving", true);
      if (!this.$refs.form.validate()) {
        this.saving = false;
        return false;
      }
      this.$http
        .post("edu-prog/update?eduProgId=" + this.form.id, this.form)
        .then((response) => {
          this.$emit("update", response.data);
          this.dialog = false;
        })
        .catch((e) => {
          if (e.response.data) {
            this.errors = e.response.data;
          } else {
            alert("error!");
          }
        })
        .finally(() => {
          this.$set(this, "saving", false);
        });
    },
  },
};
</script>