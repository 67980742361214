<template>
  <v-dialog v-model="dialog" persistent width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="ml-3"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!form || !form.id"
      >
        <v-icon>mdi-plus</v-icon></v-btn
      >
      <v-btn color="primary" :icon="!btn" dark v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-circle-edit-outline</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-toolbar color="#365d84" flat dark>
        <v-toolbar-title
          ><span v-if="!form || !form.id">Новая дисциплина</span
          ><span v-else>Изменить</span></v-toolbar-title
        ><v-spacer></v-spacer>
      <deleteBtn v-if="showDelete && form && form.id" title="Удалить"  :text="'При удалении дисциплины вся информация о ней будет безвозвартано потеряна, вы действительно хотите удалить дисцеплину: '+ form.name" :actionHref="'disciplines/delete?id='+form.id" @remove="dialog=false; $emit('remove')"/>
        <v-btn :disabled="saving" @click="dialog = false" light
          ><v-icon>mdi-close</v-icon> Отменить</v-btn
        ><v-btn :loading="saving" color="green" class="mx-3" @click="save()"
          ><v-icon>mdi-check</v-icon> Сохранить</v-btn
        >
      </v-toolbar>

      <v-card-text class="pt-3">

        <v-text-field
          v-model="form.name"
          :error="errors.name ? true : false"
          :error-messages="errors.name ? errors.name : []"
          outlined
          :counter="255"
          :rules="rules.required"
          label="Название"
          required
        ></v-text-field>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteBtn from '@/components/delete.vue'
export default {
  props: ["discipline", "eduProgId", 'btn','showDelete'],
  data() {
    return {
      dialog: false,
      saving: false,
      form: { },
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
      errors: {},
    };
    
  },
  components:{
    deleteBtn
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.form = { ...this.discipline };
      }
    }
  },
  mounted(){
    this.form = { ...this.discipline };
  },
  methods: {
    save() {
      this.saving = true;
      if (!this.form.id) {
        this.$http
          .post("disciplines/create?eduProgId=" + this.eduProgId, this.form)
          .then((response) => {
            this.$emit("create", response.data);
            this.dialog = false;
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              alert("Возникла непредвиденная ошибка");
            }
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.$http
          .post(
            "disciplines/update?eduProgId=" +
              this.eduProgId +
              "&disciplineId=" +
              this.form.id,
            this.form
          )
          .then((response) => {
            this.$emit("update", response.data);
            this.dialog = false;
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              alert("Возникла непредвиденная ошибка");
            }
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>