<template>
  <v-row>
    <v-col cols="8">
      <v-sheet outlined rounded class="px-3" style="border-bottom: none" elevation="1">
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <v-checkbox v-model="showAll" label="Показывать все компетенции"></v-checkbox>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <v-select label="Компетенция" v-if="!showAll" v-model="competenceToshow" :items="competencies"
              item-text="competence.name" item-value="id"></v-select>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet outlined elevation="3" class="pl-3 py-3" style="height: calc(100vh - 227px); overflow-y: scroll">
        <v-simple-table>
          <tbody>
            <tr v-for="competence in filteredCompetencies" :key="competence.id">

              <td style="width: 30%"><b>{{ competence.competence.type }}<span v-if="competence.competence.code"> {{ competence.competence.code }}</span>: </b>{{ competence.competence.name }}
                {{ competence.saving }}</td>
              <td>
                <v-card outlined class="pa-3 ma-3" rounded :loading="competence.saving" :disabled="competence.saving">
                  <draggable v-bind="dragOptions" @end="dragEnd" @change="onCompetenceChange(competence, $event)"
                    style="min-height: 30px; height: 100%" v-model="competence.workFunctions" :group="{
                      name: 'workFunctions',
                      pull: false,
                      put: true,
                    }">

                    <v-sheet @click="showWorkFunction(item.eduProgProfStandFunctionId)" rounded
                      v-for="item in competence.workFunctions" :key="item.id" style="cursor: pointer" v-bind:class="{
                        'grey lighten-3':
                          expanded.findIndex(
                            (el) =>
                              el.competenceId == competence.id &&
                              el.eduProgProfStandFunctionId == item.id
                          ) !== -1,
                      }" class="pa-1 ma-1 text-body-2">
                      <template v-if="item.eduProgCompetenceId">
                        <v-alert style="position: sticky; top: 0px; z-index: 1" class="py-1 px-3 ma-0 my-1" border="left"
                          dense colored-border color="deep-purple accent-4" elevation="3">
                          <v-row>
                            <v-col>
                              <div class="text-subtitle-2">
                                <b>ТФ:</b>{{ workFunctions.find(el => el.id == item.eduProgProfStandFunctionId).workFunc.name
                                }}<div style="position: absolute; right:5px; bottom: 5px"><comment  :comment="getComment('workFunction',workFunctions.find(el => el.id == item.eduProgProfStandFunctionId).workFunc)" /></div>
                              </div>
                            </v-col>
                            <v-col cols="auto">
                              <v-btn icon color="primary" @click.stop="
                                expand(competence.id, item.id)
                              ">
                                <v-icon v-if="
                                  expanded.findIndex(
                                    (el) =>
                                      el.competenceId == competence.id &&
                                      el.eduProgProfStandFunctionId == item.id
                                  ) !== -1
                                ">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                              </v-btn>
                              <v-btn icon color="red" @click.stop="
                                removeWorkFunction(
                                  competence.id,
                                  item.id,
                                )
                              ">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-alert>
                        <div v-show="
                          expanded.findIndex(
                            (el) =>
                              el.competenceId == competence.id &&
                              el.eduProgProfStandFunctionId == item.id
                          ) !== -1
                        " class="
                                  text-left
                                  font-weight-medium
                                  py-0
                                  pl-3
                                  pr-3
                                ">
                          <div
                            v-for="action in workFunctions.find(el => el.id == item.eduProgProfStandFunctionId).workFunc.workActions"
                            :key="action.id">

                            <v-alert v-if="
                              (action.skills &&
                                action.skills.length > 0)
                            " class="ma-0 my-3" dense border="left" colored-border color="orange accent-4" elevation="3">
                              <div class="text-subtitle-2 pa-3">
                                <b>ТД:</b> {{ action.name }}
                                <comment  :comment="getComment('workAction',action)" />
                              </div>
                              <v-sheet v-for="skill in action.skills" :key="skill.id" :ref="'skill' + skill.id" rounded
                                outlined elevation="3" style="cursor: pointer" class="
                                        
                                        lighten-2
                                        pa-1
                                        ma-1
                                        text-body-2
                                      "
                                      :class="skill.type=='um'?'green':'blue'">
                                <b v-if="skill.type=='um'">У:</b><b v-else>З:</b> {{ skill.name }}
                              </v-sheet>
                            </v-alert>
                          </div>
                        </div>
                      </template>
                    </v-sheet>
                  </draggable>
                </v-card>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet outlined elevation="3" class="pl-3">
        <v-row>
          <v-col>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="auto" class="pl-0">

          </v-col>
        </v-row>

        <div style="height: calc(100vh - 230px); overflow-y: scroll">
          <draggable v-bind="dragOptions" v-model="filteredRightSide" @end="dragEnd"
            style="min-height: 30px; height: 100%" :group="{
              name: 'workFunctions',
              pull: 'clone',
              put: false,
            }">
            <v-sheet  class="pa-1 ma-1 text-body-2" v-for="item in filteredRightSide" :key="item.id" style="cursor: pointer"
              rounded elevation="0" :ref="'workFunction' + item.id">
              <v-alert style="position: sticky; top: 0px; z-index: 1" class="py-1 px-3 ma-0 my-1" border="left" dense
                colored-border color="deep-purple accent-4" elevation="3" v-bind:class="
                  showAll &&
                    usedWorkFunctions.findIndex(
                      (el) => el == item.id
                    ) !== -1
                    ? 'green lighten-5'
                    : ''
                ">
                <div class="text-subtitle-2">
                  <v-row>
                    <v-col>
                      <v-tooltip top>
                          <template v-slot:activator="{on, attrs}">
                              <v-avatar v-bind="attrs" v-on="on" :style="'background-color: ' + $colorsArray[item.profStandIndex] + ' ;' " size="20" class="mr-2"/>
                          </template>
                          {{ item.profStandName }}
                      </v-tooltip>

                      <b>ТФ:</b> {{ item.workFunc.name }}
                      <comment  :comment="getComment('workFunction',item.workFunc)" />
                    </v-col>

                    <v-col cols="auto">
                      <v-btn icon color="primary" @click.stop="
                        expandRight(item.id)
                      ">
                        <v-icon v-if="
                          expandedRight.findIndex(
                            (el) =>
                              el.id == item.id
                          ) !== -1
                        ">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-alert>
              <div class="text-left font-weight-medium py-0 pl-3 pr-3" v-show="
                expandedRight.findIndex(
                  (el) =>
                    el.id == item.id
                ) !== -1
              ">
                <div v-for="action in item.workFunc.workActions" :key="action.id">
                  <v-alert v-if="
                    (action.skills && action.skills.length > 0)
                  " class="ma-0 my-3" dense border="left" colored-border color="orange accent-4" elevation="3">
                    <div class="text-subtitle-2 pa-3">
                      <b>ТД:</b> {{ action.name }}
                      <comment  :comment="getComment('workAction',action)" />
                    </div>
                    <v-sheet v-for="skill in action.skills" :key="skill.id" :ref="'skill' + skill.id" rounded outlined
                      elevation="3" style="cursor: pointer" class=" lighten-2 pa-1 ma-1 text-body-2" :class="skill.type=='um'?'green':'blue'">
                      <b v-if="skill.type=='um'">У:</b><b v-else>З:</b> {{ skill.name }}
                    </v-sheet>

                  </v-alert>
                </div>
              </div>
            </v-sheet>
          </draggable>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.highlight {
  transform: scale(0.95);
  transition: 0.5s;
}

.unhighlight {
  transform: scale(1);
  transition: 0.5s;
}
</style>
<script>
  import comment from '@/components/eduProgProfStandards/workFunctions/comment'
import draggable from "vuedraggable";
export default {
  name: "StarterPage",
  props: ["eduProgId"],
  data: () => ({
    showAll: true,
    competenceToshow: null,
    dialog: false,
    loading: false,
    search: "",
    leftSide: {},
    drag: false,
    expanded: [],
    expandedRight: [],
    competencies: [],
    workFunctions: [],
    comments:[],
    headers: [{ text: "Трудовая функция", value: "workFunc" }],
  }),
  components: {
    draggable,
    comment
  },
  computed: {
    filteredRightSide() {
      let filtered = JSON.parse(JSON.stringify(this.workFunctions));
      if (!this.showAll) {
        let data = this.competencies.find(el => el.id == this.competenceToshow);
        filtered = filtered.filter(
          (el) =>
            data.workFunctions.findIndex(
              (workFunc) => workFunc.eduProgProfStandFunctionId == el.id
            ) === -1
        );
      }
      filtered = filtered.filter(
        (el) =>
          el.workFunc.name
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
      );
      return filtered;
    },
    filteredCompetencies() {
      if (this.showAll) {
        return this.competencies;
      } else {
        return [
          this.competencies.find((el) => el.id === this.competenceToshow),
        ];
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    usedWorkFunctions() {
      let arr = [];
      for (let competence of Object.values(this.competencies)) {
        for (let workFunction of competence.workFunctions) {
          arr.push(workFunction.eduProgProfStandFunctionId);
        }
      }
      return arr;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getComment(type,target){
            let comment = this.comments.find(el=>el.targetId==target.id && type==type)
            if(!comment){
                comment={text:'',targetId:target.id}
            }
            return comment
        },
    showWorkFunction(id) {
      this.$refs["workFunction" + id][0].$el.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start",
      });
      this.$refs["workFunction" + id][0].$el.classList.add("highlight");
      setTimeout(() => {
        this.$refs["workFunction" + id][0].$el.classList.add("unhighlight");
      }, 500);

      setTimeout(() => {
        this.$refs["workFunction" + id][0].$el.classList.remove("highlight");
        this.$refs["workFunction" + id][0].$el.classList.remove("unhighlight");
      }, 1000);
    },
    expandRight(workFuncId) {
      let index = this.expandedRight.findIndex(
        (el) => el.id == workFuncId
      );
      if (index !== -1) {
        this.expandedRight.splice(index, 1);
      } else {
        this.expandedRight.push({
          id: workFuncId,
        });
      }
    },
    expand(competenceId, workFuncId) {

      let index = this.expanded.findIndex(
        (el) => el.competenceId == competenceId && el.eduProgProfStandFunctionId == workFuncId
      );
      if (index !== -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push({
          competenceId: competenceId,
          eduProgProfStandFunctionId: workFuncId,
        });
      }
    },
    removeWorkFunction(competenceId, workFunctionId, force = 0) {
      let competence = this.competencies.find(el => el.id == competenceId)
      console.log(competence)
      let index = competence.workFunctions.findIndex(el => el.id == workFunctionId)
      let competenceIx = this.competencies.findIndex(el => el.id == competence.id)
      this.$set(this.competencies[competenceIx], 'saving', true)
      this.$http
        .get("connection/competencies-work-function-unconnect", {
          params: {
            id: workFunctionId,
            force: force
          },
        })
        .then(() => {
          competence.workFunctions.splice(index, 1);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 449) {
              if (confirm(e.response.data)) {
                this.removeWorkFunction(competenceId, workFunctionId, 1)
              }
            } else {
              alert(e.response.data);
            }

          }

        }).finally(() => {
          this.$set(this.competencies[competenceIx], 'saving', false)
        })
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("connection/competencies-work-function", {
          params: {
            eduProgId: this.eduProgId,
          },
        })
        .then((response) => {
          this.workFunctions = response.data.workFunctions;
          this.competencies = response.data.competencies;
          this.competenceToshow = this.competencies[0].id;
          this.comments=response.data.comments
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCompetenceChange(competence, ev) {

      if (ev.added) {
        let element = ev.added.element;

        let count = competence.workFunctions.filter(el => el.eduProgProfStandFunctionId == element.id).length;
        console.log(count)
        if (count > 0) {
          competence.workFunctions.splice(ev.added.newIndex, 1);
          return false;
        }
        let competenceIx = this.competencies.findIndex(el => el.id == competence.id)
        this.$set(this.competencies[competenceIx], 'saving', true)
        competence.saving = true
        this.$http
          .get("connection/competencies-work-function-connect", {
            params: {
              eduProgCompetenceId: competence.id,
              eduProgProfStandFunctionId: element.id,
            },
          }).then((reponse) => {
            this.$set(competence.workFunctions, ev.added.newIndex, reponse.data)
          })
          .catch(() => {
            alert("Ошибка");
          }).finally(() => {
            this.$set(this.competencies[competenceIx], 'saving', false)
          })
      }

    },

    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
  },
};
</script>
