<template>
  <div class="d-flex flex-row h-100 w-100">
    <div class="w-100 h-100" v-if="menu && $vuetify.breakpoint.mobile" @click="menu=false" style="    background-color: rgb(0 0 0 / 50%);position: fixed; top:0px;z-index:1"></div>
    <v-list class="pa-0 h-100 elevation-12" width="350px"
      :class="{ 'naviagationVisible': menu, 'naviagationHidden': !menu, 'naviagationMobile': $vuetify.breakpoint.mobile }">
      <v-list-item class="text-body-2" to="/edu-org/list"><v-icon class="mr-3">mdi-arrow-left-circle</v-icon> К списку
        организаций</v-list-item>
      <v-list-item v-if="!loading" class="text-body-2"
        :to="'/edu-org/view/' + eduProg.eduOrgId"><v-icon class="mr-3">mdi-arrow-left-circle</v-icon> {{ eduProg.eduOrg.name
        }}</v-list-item>

      <navigate :eduProg.sync="eduProg" :loading="loading" />

    </v-list>
    <transition name="fade">
    <router-view :eduProgId="eduProg.id" :eduProg="eduProg" :periods="periods" :groups="groups"
      @groupsUpdate="groups = $event" v-if="eduProg && eduProg.id" />
    </transition>
  </div>
</template>
<style>
.naviagationVisible{
  z-index:1;
  margin-left:0px;
  transition: margin-left 0.3s;
}
.naviagationHidden{
  margin-left:-350px;
  transition: margin-left 0.3s;
}
.naviagationMobile{
  position: absolute;
  z-index:200 !important;
}
</style>
<script>
import navigate from './navigation.vue'

export default {
  name: "StarterPage",
  data: () => ({
    periods: [],
    groups: [],
    eduProg: [],
    loading: true,
  }),
  computed: {
    menu: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  components: {
    navigate,
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("edu-prog/info?eduProgId=" + this.$route.params.id)
        .then((response) => {
          this.eduProg = response.data;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$store.state.menuBtn=true
    this.fetchData();
  },
  beforeDestroy(){
    this.$store.state.menuBtn=false
  },
};
</script>
