<template>
  <div>
      <div class="text-right mt-2 mr-2">
      <export-excel
              v-if="selectedQualification?.workFunctions?.length > 0 && disciplines.length > 0"
              :fields = "json_fields"
              :name= "selectedQualification.name + '.xls'"
              :data="json_data">
          <v-btn class="text-body-2" color="green lighten-3 ">
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              Скачать Excel
          </v-btn>
      </export-excel>
      </div>
    <div class="d-flex justify-space-between px-3">
      <div style="width:600px">
        <v-select :items="qualifications" label="Квалификация" item-text="name" hide-details return-object
          v-model="selectedQualification"></v-select>
      </div>
      <v-btn-toggle class="my-1" v-model="tab" dense mandatory :multiple="false">
        <v-btn>
          <v-icon>mdi-matrix</v-icon> Матрица
        </v-btn>
        <v-btn @click="getDataSets()">
          <v-icon>mdi-chart-bar</v-icon> Гистограмма
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-simple-table height="calc(100vh - 180px)" v-if="selectedQualification.id && tab == 0" fixed-header>
      <thead>
        <tr>
          <th class="text-h6 font-weight-bold "
            style="border: 1px solid;     background-color: #C8E6C9; position: sticky; top:0px; left:0px;z-index:10"
            rowspan="2">Дисциплины</th>
          <th style="border: 1px solid; " :colspan="workFunctions.length"
            class=" text-h6 text-center font-weight-bold blue lighten-4">
            <div style="position: sticky; left:200px; width:fit-content">Трудовые функции</div>
          </th>
        </tr>
        <tr>
          <th v-for="workFunction in selectedQualification.workFunctions" :key="workFunction.id"
            style="border: 1px solid; background-color: #E3F2FD">
            {{ workFunction.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="disciplines" v-for="discipline in disciplines" :key="discipline.id">
          <td style="border: 1px solid;" class="sticky-col">{{ discipline.name }}</td>
          <td style="border: 1px solid;" align=center v-for="workFunction in selectedQualification.workFunctions"
            :key="workFunction.id">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div color="primary" dark style="width:fit-content" v-bind="attrs" v-on="on">
                  <v-icon color="green"
                    v-if="checkWorkFunction(workFunction.name, discipline.workFunctionIds)">mdi-check</v-icon>
                  <v-icon color="red" v-else>mdi-close</v-icon>
                </div>
              </template>
              <v-sheet color="grey lighten-3" class="pa-3" style="max-width:300px">
                <b>Дисциплина:</b> {{ discipline.name }}<br>
                <b>Трудовая функция:</b> {{ workFunction.name }}</v-sheet>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>


      <BarChart v-else-if="selectedQualification.id" class="pa-12" :labels="labels" :datasets="datasets" :max="max" :key='key'/>



  </div>
</template>
<script>
import BarChart from './Bar.vue'
export default {
  props: ["eduProgId"],
  data() {
    return {
      tab: 0,
      disciplines: [],
      qualifications: [],
      selectedQualification: {},
      workFunctions: [],
      loading: false,
      key:1,
      labels:[],
      max:1,
    };
  },
  components: {
    BarChart
  },
  watch:{
    selectedQualification(){
      this.getDataSets()
    }
  },
  methods: {
    getDataSets(){
      this.labels=this.disciplines.map(el=>el.name)
      let datasets=[]
      this.max=this.selectedQualification.workFunctions.length
      for(let workFunction of this.selectedQualification.workFunctions){
        let set={label: workFunction.name, backgroundColor: this.getRandomColor(),data:[]}
        let workFunctionId = this.workFunctions.find(el => el.name.replaceAll(' ', '') == workFunction.name.replaceAll(' ', ''))
        if(workFunctionId){
          workFunctionId = workFunctionId.id
          
        }
        for(let discipline of this.disciplines){

          if(workFunctionId && discipline.workFunctionIds.findIndex(el=>el==workFunctionId)!==-1){
            set.data.push(1)
          } else {
            set.data.push(0)
          }
        }
        datasets.push(set)
      }
      this.datasets=datasets
      this.key++
    },
    getRandomColor() {
            let rnd1 = (Math.random() * 254 + 1)
            let rnd2 = (Math.random() * 254 + 1)
            let rnd3 = (Math.random() * 254 + 1)
            return 'rgb(' + rnd1 + ' ' + rnd2 + ' ' + rnd3 + ')';
        },
    checkWorkFunction(workFunctionName, workFunctionIds) {
      let workFunction = this.workFunctions.find(el => el.name.replaceAll(' ', '') == workFunctionName.replaceAll(' ', ''))
      if (workFunction) {
        return workFunctionIds.findIndex(el => el == workFunction.id) !== -1
      }

    },
    fetchQualifications() {
      this.loading = true;

      this.$http
        .get("matrix/qualifications-work-functions", {
          params: {
            eduProgId: this.eduProgId
          },
        })
        .then((response) => {
          this.qualifications = response.data;
          this.selectedQualification = this.qualifications[0]
        })
        .finally(() => {
          this.loading = false;
        });

    },
    fetchData() {
      this.loading = true;

      this.$http
        .get("matrix/disciplines-work-functions", {
          params: {
            eduProgId: this.eduProgId
          },
        })
        .then((response) => {
          this.disciplines = response.data.disciplines;
          this.workFunctions = response.data.workFunctions;

        })
        .finally(() => {
          this.loading = false;
        });

    }
  },

    computed:{
        json_data() {
            // let data = []
            let workFunctions = []
            for(let disc of this.disciplines){
                let object = {}
                for(let workFunc of this.selectedQualification?.workFunctions){
                    object.discipline = disc.name
                    object[workFunc.id] = this.checkWorkFunction(workFunc.name, disc.workFunctionIds) ? 'Да' : ''
                    // console.log(object)
                }
                console.log(object)

                workFunctions.push(object)

            }
            return workFunctions
        },
        json_fields() {
            let data = {
                'Дисциплина': 'discipline',
            }

            for (let workFunc of this.selectedQualification?.workFunctions) {
                data[workFunc.name] = workFunc.id
            }

            return data

        },
    },

  mounted() {
    this.fetchQualifications()
    this.fetchData()
  }
};
</script>
<style>
#bar-chart{
  pointer-events:all;
}
.disciplines:hover>td:first-child {
  background-color: #A5D6A7;
}

.disciplines>td:first-child {
  background-color: #E8F5E9;
}


.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  left: 0px;
  z-index: 9
}
</style>
