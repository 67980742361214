<template>
  <div>

    <div class="text-right mt-2 mr-2">
        <export-excel
                v-if="workFunctions.length > 0 && disciplines.length > 0"
                :fields = "json_fields"
                :data="json_data">
            <v-btn class="text-body-2" color="green lighten-3 ">
                <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                Скачать Excel
            </v-btn>
        </export-excel>

    </div>

  <v-simple-table  class="py-3" fixed-header height="calc(100vh - 140px)">
    <thead>
      <tr>
        <th class="text-h6 font-weight-bold green lighten-4" style="border: 1px solid;" rowspan="2">Дисциплины</th>
        <th style="border: 1px solid;" :colspan="workFunctions.length"
          class=" text-h6 text-center font-weight-bold blue lighten-4">Трудовые функции</th>
      </tr>
      <tr>
        <th v-for="workFunction in workFunctions" :key="workFunction.id" style="border: 1px solid; background-color: #E3F2FD">
          {{ workFunction.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="disciplines" v-for="discipline in disciplines" :key="discipline.id">
        <td style="border: 1px solid;" >{{ discipline.name }}</td>
        <td style="border: 1px solid;" align=center v-for="workFunction in workFunctions" :key="workFunction.id">
          <v-menu offset-y >
      <template v-slot:activator="{ on, attrs }">
        <div
          color="primary"
          dark
          style="width:fit-content"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="green" v-if="discipline.workFunctionIds.findIndex(el=>el==workFunction.id)!==-1">mdi-check</v-icon>
          <v-icon color="red" v-else>mdi-close</v-icon>
          </div>
      </template>
      <v-sheet color="grey lighten-3"  class="pa-3" style="max-width:300px">
      <b>Дисциплина:</b> {{ discipline.name }}<br>
      <b>Трудовая функция:</b> {{ workFunction.name }}</v-sheet>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
  </div>

</template>
<script>

export default {
  props: ["eduProgId"],
  data() {
    return {
      disciplines: [],
      workFunctions: [],
      loading: false,
    };
  },
  components: {
  },
  methods: {
    onInit: function (panzoomInstance) {
      let that = this;
      panzoomInstance.setTransformOrigin(null);
      panzoomInstance.on("panstart", function () {
        that.isDragging = true;
        // Note: e === instance.
      });
      panzoomInstance.moveTo(0, 0);
      panzoomInstance.zoomTo(0, 0, 1);
      panzoomInstance.on("panend", function () {
        that.isDragging = false;
        // Note: e === instance.
      });
    },

    onTouch: function () {
      // `e` - is current touch event.

      return false; // tells the library to not preventDefault.
    },
    fetchData() {
      this.loading = true;

      this.$http
        .get("matrix/disciplines-work-functions", {
          params: {
            eduProgId: this.eduProgId
          },
        })
        .then((response) => {
          this.disciplines = response.data.disciplines;
          this.workFunctions = response.data.workFunctions;
        })
        .finally(() => {
          this.loading = false;
        });

    }
  },

    computed:{
        json_data() {
            // let data = []
            let workFunctions = []
            for(let disc of this.disciplines){
                let object = {}
                for(let workFunc of this.workFunctions){
                    object.discipline = disc.name
                    object[workFunc.id] = disc.workFunctionIds?.findIndex(el=>el==workFunc.id)!==-1 ? 'Да' : ''
                    // console.log(object)
                }
                workFunctions.push(object)
            }
            return workFunctions
        },
        json_fields() {
            let data = {
                'Дисциплина': 'discipline',
            }

            for (let workFunc of this.workFunctions) {
                data[workFunc.name] = workFunc.id
            }

            return data

        },
    },

  mounted() {
    this.fetchData()
  }
};
</script>
<style>
.disciplines:hover > td:first-child{
  background-color: #A5D6A7;
}
.disciplines > td:first-child{
  background-color: #E8F5E9;
}

.wrapperDisabled {
  pointer-events: none;
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  left: 0px;
  z-index:9
}
</style>
