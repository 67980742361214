<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title>Редактор связей</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-tabs v-model="tab">
          <v-tab>
              Связь компетенций с трудовыми функциями
              <v-tooltip bottom max-width="500">
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" class="ml-3">mdi-information</v-icon>
                  </template>
                  На данном этапе Вам необходимо <b>«перетащить»</b> трудовую функцию к компетенции(ям), формируемым в рамках освоения образовательной программы.
                  <br/><br/><b>Обращаем внимание, что к одной профессиональной компетенции могут относится несколько трудовых функций. Таким же образом одна трудовая функция может отражаться в нескольких компетенциях.</b>
                  <br/><br/>Данный подход обусловлен вариативностью выбора, а также разнообразием формулировок профессиональных компетенций.
              </v-tooltip>
          </v-tab>
          <v-tab>
              Связь умений и знаний с дисциплинами
              <v-tooltip bottom max-width="500">
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" class="ml-3">mdi-information</v-icon>
                  </template>
                  На данном этапе Вам необходимо связать Умения и Знания, содержащиеся в Профессиональных стандартах, с дисциплинами (модулями) учебного плана.
                  <br/><br/>Соотнесение дисциплин (модулей) к Умениям и Знаниям отразит процент % соответствия образовательной программы выбранным трудовым функциям.
                  <br/><br/><b>Обращаем внимание на то, что дисциплины (модули) могут дублироваться в разных Знаниях и Умениях.</b>
              </v-tooltip>
          </v-tab>
          <v-tab>Визуализация связей дисциплин</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item class="mx-3">
            <v-lazy>
              <workFunctionsConnect :eduProgId="eduProgId" ref="connect"/>
            </v-lazy>
          </v-tab-item>

          <v-tab-item>
            <v-lazy>
              <visual ref='disciplineConnection' :eduProgId="eduProgId" />
            </v-lazy>
          </v-tab-item>
          <v-tab-item>
            <v-lazy>
            <disciplineConnectionVisual ref="disciplineConnectionVisual" :eduProgId="eduProgId" />
            </v-lazy>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import disciplineConnectionVisual from '@/components/eduProgProfStandards/connection/disciplineConnectionVisual.vue'
import visual from '@/components/eduProgProfStandards/connection/visual.vue'
import workFunctionsConnect from '@/components/eduProgProfStandards/connection/workFunctions.vue';
export default {
  props: ["eduProgId"],
  data() {
    return {
      tab: 0,
      dialog: false,
    };
  },
  watch: {
    dialog(val){
      if(val==true){
        if(this.$refs.connect){
this.$refs.connect.fetchData()
        }
        
      }
    },
    tab(val) {
      if (val == 1) {
        if (this.$refs.disciplineConnection) {
                    this.$nextTick(()=>{
            this.$refs.disciplineConnection.redraw()
          })
          
        }
      }
      if (val == 2) {
        if (this.$refs.disciplineConnectionVisual) {
          this.$nextTick(()=>{
            this.$refs.disciplineConnectionVisual.redraw()
          })
          
        }
      }
    }
  },
  components: {
    workFunctionsConnect,
    visual,
    disciplineConnectionVisual
  }
};
</script>
