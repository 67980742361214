<template>

    <v-list>
      <v-list-item color="#365d84" style="background-color: #365d84 ;"
      dark>
      <v-list-item-avatar>
          <v-icon
            class="grey lighten-1"
            dark
          >
          mdi-information
          </v-icon>
        </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Информация</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <infoEdit v-if="!loading &&  $checkRole(eduProg.eduOrgId,'admin')" :eduProg.sync="eduProg" :btn="true" @update="Object.assign(eduProg, $event)" />
        </v-list-item-action>

    </v-list-item>
        <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Роли</v-list-item-title>
      </v-list-item-content>
      
      <v-list-item-action>
        <group v-if="$checkRole(eduProg.eduOrgId,'admin')" type="methodist" :eduOrgId="eduProg.eduOrgId" :count="eduProg.rolesCount" class="mb-3" :loading="loading" /> 
        </v-list-item-action>
    </v-list-item>
      <v-list-item :to="'/edu-prog/view/' + eduProg.id + '/info'">
        <v-list-item-content>
          <v-list-item-title>Основная информация</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="'/edu-prog/view/' + eduProg.id + '/edu-stand'">
        <v-list-item-content>
          <v-list-item-title>1. Компетенции</v-list-item-title>
          
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="'/edu-prog/view/' + eduProg.id + '/prof-stand'">
        <v-list-item-content>
          <v-list-item-title>2. Профессиональные стандарты</v-list-item-title>
          <v-list-item-subtitle>Выбор трудовых функций</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$refs.connect.dialog = true">
        <v-list-item-content>
          <v-list-item-title>3. Связи</v-list-item-title>
          <v-list-item-subtitle>Компетенции, трудовые функции, дисциплины</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-launch</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item :to="'/edu-prog/view/' + eduProg.id + '/disciplines'" >
        <v-list-item-content>
          <v-list-item-title>4. Дисциплины</v-list-item-title>
          <v-list-item-subtitle>Темы, связи с умениями и знаниями</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$refs.matrix.dialog = true">
        <v-list-item-content>
          <v-list-item-title>5. Матрицы</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-launch</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item :to="'/edu-prog/view/' + eduProg.id + '/study'" disabled v-if="1!==1">
        <v-list-item-content>
          <v-list-item-title>5. Проверки</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="donwload()" :loading="true" :disabled="downloading">
        <v-list-item-content>
          <v-list-item-title><v-icon v-if="!downloading">mdi-file-word-outline</v-icon><v-progress-circular v-else
        indeterminate
        size="24"
      ></v-progress-circular> Скачать КМВ</v-list-item-title>
          <v-list-item-subtitle>Квалификационная модель выпускника</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <connect :eduProgId="eduProg.id" ref="connect" />
    <matrix :eduProgId="eduProg.id" ref="matrix"/>
    </v-list>
 
  
</template>

<script>
import connect from './profStandards/connection.vue'
import matrix from '@/components/eduProgDisciplines/matrix/matrix.vue'
import group from './roles/group'
import infoEdit from "./info/infoEdit.vue";
export default {
  props: ['eduProg','loading'],
  components: {
    connect,
    matrix,
    group,
    infoEdit
  },
  data(){
    return {
      downloading:false
    }
  },
  methods:{
    donwload() {
      this.downloading=true
      this.$http.get('/documents/download-cmv',{
        params:{
          eduProgId:this.eduProg.id
        },
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('href', href);
        link.setAttribute('download', Date.now()+'file.docx'); //or any other extension
        link.setAttribute('target', '_blank');
        //link.setAttribute('onclick', "window.open(this.href,'_blank');return false;");
        
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }).catch(()=>{
        alert('Ошибка')
      }).finally(()=>{
        this.downloading=false
      })
    }
  }
}
</script>

<style>
</style>