<template>


  <div style="overflow: hidden; height: calc(100vh - 135px);position:relative">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <panZoom :options="{
      minZoom: 0.1,
      maxZoom: 2,
      bound: true,
    }" @init="onInit" @onTouch="onTouch">
      <div ref="visualContainer" style="width: 1980px;">
        <canvas id="canvas1"></canvas>
        <v-row>
          <v-col cols="2">
            <div v-for="discipline in disciplines" :key="discipline.id">
              <v-sheet style="cursor: pointer" :ref="'discipline' + discipline.id" outlined rounded class="pa-3 ma-2"
                :elevation="
                  selectedDiscipline && selectedDiscipline === discipline.id
                    ? 12
                    : 0
                " v-bind:class="{
                  'grey lighten-2 grey--text':
                    selectedDiscipline && selectedDiscipline !== discipline.id,
                }" @click="connectOne(discipline.id)" @mouseenter="connectAll(discipline.id)" @mouseleave="connectAll()">
                <b>{{ discipline.name }} </b>
              </v-sheet>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row>
              <v-col cols="4">
                <div v-for="competence in competencies" :key="competence.id">
                  <div>
                    <v-sheet v-for="customSkill in customFilter(competence)" :key="customSkill.id" rounded outlined
                      :ref="'custom' + customSkill.id" style="cursor: pointer"
                      v-bind:class="customSkill.type == 'skill' ? 'green' : 'blue'"
                      class="lighten-4 pa-1 ma-1 text-body-2">

                      <b v-if="customSkill.type == 'skill'">У:</b>
                      <b v-else>З:</b> {{ customSkill.name }}

                    </v-sheet>
                  </div>
                </div>
              </v-col>
            </v-row>

            <div v-for="workFunction in filteredWorkFunctions" :key="workFunction.id">
              <v-row>
                <v-col>
                  <div v-for="workAction in workFunction.workActions" :key="workAction.id">
                    <v-row>
                      <v-col class="py-0">
                        <v-sheet v-for="skill in workAction.skills" :key="skill.id" :ref="'skill' + skill.id" rounded
                          outlined class="lighten-4 pa-1 ma-1 text-body-2" :class="skill.type=='um'?'green':'blue'">
                          <b v-if="skill.type=='um'">У:</b><b v-else>З:</b> {{ skill.name }}
                        </v-sheet>

                      </v-col>
                      <v-col cols="2"></v-col>
                      <v-col cols="4" class="d-flex align-center py-0">
                        <v-alert :ref="'action' + workAction.id" class="py-1 px-3 ma-0 my-1" border="left" dense
                          colored-border color="orange accent-4" elevation="3">
                          <div class="text-subtitle-2">
                            <b>ТД:</b>
                            {{ workAction.name }}
                          </div>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="3" class="d-flex align-center py-0">
                  <v-alert :ref="'workFunction' + workFunction.id" style="position: sticky; top: 0px; z-index: 1"
                    class="py-1 px-3 ma-0 my-1" border="left" dense colored-border color="deep-purple accent-4"
                    elevation="3">
                    <div class="text-subtitle-2">
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <v-avatar v-bind="attrs" v-on="on" :style="'background-color: ' + $colorsArray[workFunction.profStandIndex] + ' ;' " size="20" class="mr-2"/>
                            </template>
                            {{ workFunction.profStandName }}
                        </v-tooltip>
                      <b>ТФ:</b>
                      {{ workFunction.name }}
                      <div class="text-right"><comment  :comment="getComment('workFunction',workFunction)" /></div>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="1"></v-col>
          <v-col cols="2">
            <div v-for="competence in filteredCompetencies" :key="competence.id">
              <v-sheet :ref="'competence' + competence.id" outlined rounded class="pa-3 ma-2">
                <b>{{ competence.competence.type }}<span v-if="competence.competence.code"> {{ competence.competence.code }}</span>: </b> {{ competence.competence.name }}
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </div>
    </panZoom>
  </div>
</template>

<script>
import comment from '@/components/eduProgProfStandards/workFunctions/comment'
export default {
  props: ["eduProgId"],
  data() {
    return {
      loading: false,
      dialog: false,
      ctx: null,
      canvas: null,
      selectedDiscipline: false,
      workFunctions: [],
      competencies: [],
      disciplines: [],
      selectedSkills: [],
      selectedCustomSkills: [],
      discipline: {},
      comments:[]
    };
  },
  components: {
    comment
  },
  computed: {
    filteredCompetencies() {      
      if(!this.selectedDiscipline){return []}  
        let workFunctionIds = this.filteredWorkFunctions.map(el => el.id)
        let discipline = this.disciplines.find(el => el.id == this.selectedDiscipline)
        let disciplineCompetenceIds = discipline.skills.map(el=>el.competenceId)
        return this.competencies.filter(competence => disciplineCompetenceIds.includes(competence.id) && competence.workFunctions.findIndex(wf => workFunctionIds.findIndex(el => el == wf) !== -1) !== -1 
        || discipline.customSkills.findIndex(dcs => competence.customSkills.map(el => el.id).findIndex(cs => cs == dcs.skillId) !== -1) !== -1)
    },
    // disciplineCompetenciesIds(){
    //   let disciplineWorkFunctions=[...this.selectedDiscipline.skills.map(el=>el.competenceId),...this.selectedDiscipline.skills.map(el=>el.competenceId)
    // },
    filteredWorkFunctions() {
      let disciplines = []
      if (this.selectedDiscipline) {
        disciplines = [this.disciplines.find(el => el.id == this.selectedDiscipline)]
      } else {
        disciplines = this.disciplines
      }
      let skillIds = []
      for (let discipline of disciplines) {
        skillIds = skillIds.concat(discipline.skills.map(el => el.skillId))
      }
      let workFunctionsClone = JSON.parse(JSON.stringify(Object.values(this.workFunctions)))
      for (let workFunctionIdx in workFunctionsClone) {
        for (let workActionIdx in workFunctionsClone[workFunctionIdx].workActions) {
          workFunctionsClone[workFunctionIdx].workActions[workActionIdx].skills = workFunctionsClone[workFunctionIdx].workActions[workActionIdx].skills.filter(el => skillIds.findIndex(skillId => skillId == el.id) !== -1)
        }
        workFunctionsClone[workFunctionIdx].workActions = workFunctionsClone[workFunctionIdx].workActions.filter(el => el.skills.length > 0)
      }
      workFunctionsClone = workFunctionsClone.filter(el => el.workActions.length > 0)
      return workFunctionsClone;
    }
  },
  destroyed() { },
  mounted() {
    this.redraw()
  },
  methods: {
    getComment(type,target){
            let comment = this.comments.find(el=>el.targetId==target.id && type==type)
            if(!comment){
                comment={text:'',targetId:target.id}
            }
            return comment
        },
    customFilter(competence) {
      let disciplines = []
      if (this.selectedDiscipline) {
        disciplines = [this.disciplines.find(el => el.id == this.selectedDiscipline)]
      } else {
        disciplines = this.disciplines
      }
      let customIds = []
      for (let discipline of disciplines) {
        customIds = customIds.concat(discipline.customSkills.map(el => el.skillId))
      }
      let customSkills = JSON.parse(JSON.stringify(Object.values(competence.customSkills)))
      return customSkills.filter(el => customIds.findIndex(skillId => skillId == el.id) !== -1)
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("connection/competencies-work-function-visual", {
          params: {
            eduProgId: this.eduProgId,
          },
        })
        .then((response) => {
          this.workFunctions = response.data.workFunctions;
          this.competencies = response.data.competencies;
          this.disciplines = response.data.disciplines;
          this.comments = response.data.comments
          this.selectedDiscipline = response.data.disciplines[0].id;
        })
        .finally(() => {
          this.connectAll();
          this.loading = false;
        });
    },
    onInit: function (panzoomInstance) {
      let that = this;
      panzoomInstance.setTransformOrigin(null);
      panzoomInstance.moveTo(0, 0);
      panzoomInstance.on("panstart", function () {
        that.isDragging = true;
        // Note: e === instance.
      });
      panzoomInstance.on("panend", function () {
        that.isDragging = false;
        // Note: e === instance.
      });
    },

    onTouch: function () {
      // `e` - is current touch event.

      return false; // tells the library to not preventDefault.
    },
    redraw() {
      this.canvas = document.getElementById("canvas1");
      this.ctx = this.canvas.getContext("2d");
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    connectOne(disciplineId) {

        this.selectedDiscipline = disciplineId;


      this.$nextTick(() => {
        this.connectAll(disciplineId);
      });
    },
    connect(ref1, ref2, color = "grey", width = 1) {
      this.drawLine(ref1, ref2, "left", color, width);
    },
    connectAll(disciplineId) {
      let disciplines = []
      if (this.selectedDiscipline) {
        disciplines = [this.disciplines.find(el => el.id == this.selectedDiscipline)]
      } else {
        disciplines = this.disciplines
      }
      let rect = this.getOffset(this.$refs.visualContainer);
      this.canvas.width = rect.width;
      this.canvas.height = rect.height;
      this.ctx.clearRect(0, 0, rect.width, rect.height);
      let color = "grey";
      let width = 1;
      let selectedSkills = []
      let selectedCustom = []

      for (let discipline of disciplines) {
        if (disciplineId == discipline.id) {
          color = null
          width = 2
        } else {
          color = "grey";
          width = 1;
        }

        for (let skill of discipline.skills) {
          if (!this.$refs["skill" + skill.skillId]) {
            continue;
          }
          if (disciplineId == discipline.id) {
            selectedSkills.push(skill.skillId)
          }
          this.drawLine(
            this.$refs["discipline" + discipline.id][0].$el,
            this.$refs["skill" + skill.skillId][0].$el,
            "right",
            color,
            width
          );
        }
        for (let custom of discipline.customSkills) {
          if (!this.$refs["custom" + custom.skillId]) {
            continue;
          }
          if (disciplineId == discipline.id) {
            selectedCustom.push(custom.skillId)
          }
          this.drawLine(
            this.$refs["discipline" + discipline.id][0].$el,
            this.$refs["custom" + custom.skillId][0].$el,
            "right",
            color,
            width
          );
        }
      }

      let competencies = JSON.parse(JSON.stringify(this.competencies));
      for (let competence of competencies) {
        for (let custom of competence.customSkills) {
          if (selectedCustom.findIndex(el => el == custom.id) !== -1) {
            color = null;
            width = 2;
          } else {
            color = "grey";
            width = 1;
          }

          if (this.$refs["custom" + custom.id] && this.$refs["custom" + custom.id][0]) {
            this.drawLine(
              this.$refs["custom" + custom.id][0].$el,
              this.$refs["competence" + competence.id][0].$el,
              "right",
              color,
              width
            );
          }
        }
      }
      for (let workFunc of this.filteredWorkFunctions) {
        let color = "grey";
        let width = 1;
        for (let action of workFunc.workActions) {

          for (let skill of action.skills) {
            if (selectedSkills.findIndex(el => el == skill.id) !== -1) {
              color = null;
              width = 2;
            }
            this.drawLine(
              this.$refs["skill" + skill.id][0].$el,
              this.$refs["action" + action.id][0].$el,
              "right",
              color,
              width
            );
          }
          this.drawLine(
            this.$refs["action" + action.id][0].$el,
            this.$refs["workFunction" + workFunc.id][0].$el,
            "right",
            color,
            width
          );
        }
        //let competitionCon = competencies.filter(el => el.workFunctions.findIndex(wf => wf == workFunc.id) !== -1)
        for (let con of this.filteredCompetencies) {
          this.drawLine(
            this.$refs["workFunction" + workFunc.id][0].$el,
            this.$refs["competence" + con.id][0].$el,
            "right",
            color,
            width
          );
        }
      }

    },
    drawLine(el1, el2, direction, color, width) {
      if (!color) {
        var ramp = this.ctx.createLinearGradient(0, 0, this.canvas.width, 0);
        ramp.addColorStop("0", "blue");
        ramp.addColorStop("0.8", "magenta");
        ramp.addColorStop("1", "red");
        this.ctx.strokeStyle = ramp;
      } else {
        this.ctx.strokeStyle = color;
      }

      this.ctx.lineWidth = width;
      let offset1 = this.getOffset(el1);
      let offset2 = this.getOffset(el2);

      this.ctx.beginPath();

      let fromX, fromY, toX, toY;
      if (direction == "right") {
        fromX = offset1.left + offset1.width;
        fromY = offset1.top + offset1.height / 2;
        toX = offset2.left - 5;
        toY = offset2.top + offset2.height / 2;
      } else {
        fromX = offset1.left;
        fromY = offset1.top + offset1.height / 2;
        toX = offset2.left + offset2.width;
        toY = offset2.top + offset2.height / 2;
      }
      this.ctx.moveTo(fromX, fromY);
      this.ctx.lineTo(toX, toY);
      this.ctx.stroke();

      var headlen = 20; // length of head in pixels
      var dx = toX - fromX;
      var dy = toY - fromY;
      var angle = Math.atan2(dy, dx);
      this.ctx.moveTo(fromX, fromY);
      this.ctx.lineTo(toX, toY);
      this.ctx.lineTo(
        toX - headlen * Math.cos(angle - Math.PI / 6),
        toY - headlen * Math.sin(angle - Math.PI / 6)
      );
      this.ctx.moveTo(toX, toY);
      this.ctx.lineTo(
        toX - headlen * Math.cos(angle + Math.PI / 6),
        toY - headlen * Math.sin(angle + Math.PI / 6)
      );
      this.ctx.stroke();
    },
    getOffset(el) {
      return {
        left: el.offsetLeft,
        top: el.offsetTop,
        width: el.offsetWidth,
        height: el.offsetHeight,
      };
    },
  },
};
</script>

<style>
.wrapperDisabled {
  pointer-events: none;
}

canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  box-sizing: border-box;
  pointer-events: none;
}
.vue-pan-zoom-scene{
  position: absolute;
  height:100%;
  width:100%;
}
.vue-pan-zoom-item{
  height:100%;
  width:100%;
}
</style>
