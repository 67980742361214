<template>
  <div>
    <v-autocomplete v-model="selected" :items="Object.values(disciplines)" outlined item-text="name" item-value="id"
      chips label="Дисциплины" multiple>
      <template v-slot:prepend-item>
        <div class="text-center mb-2">
          <desciplineEdit @create="create" :discipline="{}" :eduProgId="eduProgId" />
        </div>
      </template>

      <template v-slot:item="data">
        <div>
          <v-checkbox v-model="data.attrs.inputValue" :label="data.item.name" hide-details class="mt-0">
            <template v-slot:append>
              <div class="ma-0 mt-n1 pa-0">
                <desciplineEdit @remove="remove(data.item.id)" :showDelete="true" @update="update"
                  :discipline="data.item" :eduProgId="eduProgId" />
              </div>
            </template>
          </v-checkbox>
        </div>
      </template>
    </v-autocomplete>
    <!-- <v-sheet v-for="discipline of selectedInOtherCompetencies" :key="discipline.id" elevation="1" class="pa-1 rounded-lg" outlined>
      <v-icon style="position: absolute; top:3px;right:3px;">mdi-question</v-icon>
      <div class="text-body-2">{{discipline.name}}</div>
      <div v-for="skill in groupBy('competenceId')(discipline.skills.filter(el=>el.competenceId!=competenceId))" :key="skill[0].id" class="ml-3 text-caption">
        <b>{{ competencies.find(el=>el.id==skill[0].competenceId).competence.type }} {{ competencies.find(el=>el.id==skill[0].competenceId).competence.code }}:</b> {{competencies.find(el=>el.id==skill[0].competenceId).competence.name}}
      </div>
      <div v-for="knowledge in groupBy('competenceId')(discipline.knowledges.filter(el=>el.competenceId!=competenceId))" :key="knowledge[0].id" class="ml-3 text-caption">
        <b>{{ competencies.find(el=>el.id==knowledge[0].competenceId).competence.type }} {{ competencies.find(el=>el.id==knowledge[0].competenceId).competence.code }}:</b> {{competencies.find(el=>el.id==knowledge[0].competenceId).competence.name}}
      </div>
    </v-sheet> -->
  </div>
</template>

<script>
import desciplineEdit from '@/components/eduProgDisciplines/edit.vue'
export default {
  props: ['disciplines', 'skillKnowledgeId', 'eduProgId', 'type','workFunctionId','competenceId','competencies'],
  mounted() {
    this.init()
  },
  components: {
    desciplineEdit
  },
  computed:{
    selectedInOtherCompetencies(){
      switch (this.type) {
        //Заменить коментом если будет нужна свзяь с конкретной компетенцией
        case 'skill':
        return this.disciplines.filter(el => el.skills.findIndex(skill => skill.skillId == this.skillKnowledgeId) !== -1 ).filter(el=>!this.selected.includes(el.id))
  
        case 'customSkill':
          return this.disciplines.filter(el => el.customSkills.findIndex(skill => skill.skillId == this.skillKnowledgeId) !== -1).filter(el=>!this.selected.includes(el.id))

      }
      return []
    }
  },
  methods: {
    groupBy(key) {
  return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
},
    init() {
      this.loading = true
      switch (this.type) {
        //Заменить коментом если будет нужна свзяь с конкретной компетенцией
        case 'skill':
          this.selected = this.disciplines.filter(el => el.skills.findIndex(skill => skill.skillId == this.skillKnowledgeId && this.competenceId==skill.competenceId) !== -1 ).map(el => el.id)
          break;
        case 'customSkill':
          this.selected = this.disciplines.filter(el => el.customSkills.findIndex(skill => skill.skillId == this.skillKnowledgeId && this.competenceId==skill.competenceId) !== -1).map(el => el.id)
          break;
      }

      this.$nextTick(() => {
        this.loading = false
      })
    },
    update(data) {
      let disc = this.disciplines.find(el => el.id === data.id)
      Object.assign(disc, data)
    },
    create(data) {
      this.disciplines.push({ skills: [], customSkills: [], ...data })
    },
    remove(disciplineId) {
      let index = this.disciplines.findIndex(el => el.id === disciplineId)
      this.disciplines.splice(index, 1)
    }
  },
  watch: {
    competenceId(){
      this.init()
    },
    selected(newVal, oldVal) {
      if (!this.loading) {
        let newElements = newVal.filter(x => !oldVal.includes(x));
        if (newElements.length > 0) {

          this.$http.get('connection/discipline-with-skills-connect', {
            params: {
              type: this.type,
              disciplineId: newElements[0],
              competenceId: this.competenceId,
              id: this.skillKnowledgeId,
              workFunctionId: this.workFunctionId
            }
          }).then((response) => {
            let discipline = this.disciplines.find(el => el.id == newElements[0])
            switch (this.type) {
              //Заменить коментом если будет нужна свзяь с конкретной компетенцией
              case 'skill':
                discipline.skills.push(response.data)
                break;
              case 'customSkill':
                discipline.customSkills.push(response.data)
                break;
            }
          })

        }
        let removeElements = oldVal.filter(x => !newVal.includes(x));
        if (removeElements.length > 0) {
          this.$http.get('connection/discipline-with-skills-unconnect', {
            params: {
              type: this.type,
              disciplineId: removeElements[0],
              competenceId: this.competenceId,
              id: this.skillKnowledgeId,
              //competenceId: this.competenceId
            }
          }).then(() => {
            let discipline = this.disciplines.find(el => el.id == removeElements[0])
            let index = -1
            switch (this.type) {
              //Заменить коментом если будет нужна свзяь с конкретной компетенцией
              case 'skill':
                index = discipline.skills.findIndex(skill => skill.skillId == this.skillKnowledgeId)
                discipline.skills.splice(index, 1)
                break;
              case 'customSkill':
                index = discipline.customSkills.findIndex(skill => skill.skillId == this.skillKnowledgeId)
                discipline.splice(index, 1)
                break;
            }
          })

        }
        this.$nextTick(() => {
          this.$parent.$parent.connectAll()
        })

      }

    },
  },
  data: () => ({
    selectedDiscipline: {},
    loading: true,
    selected: []
  }),
}
</script>

<style>
</style>