var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-sheet',{staticClass:"px-3",staticStyle:{"border-bottom":"none"},attrs:{"outlined":"","rounded":"","elevation":"1"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":"Показывать все компетенции"},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[(!_vm.showAll)?_c('v-select',{attrs:{"label":"Компетенция","items":_vm.competencies,"item-text":"competence.name","item-value":"id"},model:{value:(_vm.competenceToshow),callback:function ($$v) {_vm.competenceToshow=$$v},expression:"competenceToshow"}}):_vm._e()],1)],1)],1),_c('v-sheet',{staticClass:"pl-3 py-3",staticStyle:{"height":"calc(100vh - 227px)","overflow-y":"scroll"},attrs:{"outlined":"","elevation":"3"}},[_c('v-simple-table',[_c('tbody',_vm._l((_vm.filteredCompetencies),function(competence){return _c('tr',{key:competence.id},[_c('td',{staticStyle:{"width":"30%"}},[_c('b',[_vm._v(_vm._s(competence.competence.type)),(competence.competence.code)?_c('span',[_vm._v(" "+_vm._s(competence.competence.code))]):_vm._e(),_vm._v(": ")]),_vm._v(_vm._s(competence.competence.name)+" "+_vm._s(competence.saving))]),_c('td',[_c('v-card',{staticClass:"pa-3 ma-3",attrs:{"outlined":"","rounded":"","loading":competence.saving,"disabled":competence.saving}},[_c('draggable',_vm._b({staticStyle:{"min-height":"30px","height":"100%"},attrs:{"group":{
                    name: 'workFunctions',
                    pull: false,
                    put: true,
                  }},on:{"end":_vm.dragEnd,"change":function($event){return _vm.onCompetenceChange(competence, $event)}},model:{value:(competence.workFunctions),callback:function ($$v) {_vm.$set(competence, "workFunctions", $$v)},expression:"competence.workFunctions"}},'draggable',_vm.dragOptions,false),_vm._l((competence.workFunctions),function(item){return _c('v-sheet',{key:item.id,staticClass:"pa-1 ma-1 text-body-2",class:{
                      'grey lighten-3':
                        _vm.expanded.findIndex(
                          (el) =>
                            el.competenceId == competence.id &&
                            el.eduProgProfStandFunctionId == item.id
                        ) !== -1,
                    },staticStyle:{"cursor":"pointer"},attrs:{"rounded":""},on:{"click":function($event){return _vm.showWorkFunction(item.eduProgProfStandFunctionId)}}},[(item.eduProgCompetenceId)?[_c('v-alert',{staticClass:"py-1 px-3 ma-0 my-1",staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"border":"left","dense":"","colored-border":"","color":"deep-purple accent-4","elevation":"3"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-subtitle-2"},[_c('b',[_vm._v("ТФ:")]),_vm._v(_vm._s(_vm.workFunctions.find(el => el.id == item.eduProgProfStandFunctionId).workFunc.name)),_c('div',{staticStyle:{"position":"absolute","right":"5px","bottom":"5px"}},[_c('comment',{attrs:{"comment":_vm.getComment('workFunction',_vm.workFunctions.find(el => el.id == item.eduProgProfStandFunctionId).workFunc)}})],1)])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.expand(competence.id, item.id)}}},[(
                                _vm.expanded.findIndex(
                                  (el) =>
                                    el.competenceId == competence.id &&
                                    el.eduProgProfStandFunctionId == item.id
                                ) !== -1
                              )?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeWorkFunction(
                                competence.id,
                                item.id,
                              )}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.expanded.findIndex(
                          (el) =>
                            el.competenceId == competence.id &&
                            el.eduProgProfStandFunctionId == item.id
                        ) !== -1
                      ),expression:"\n                        expanded.findIndex(\n                          (el) =>\n                            el.competenceId == competence.id &&\n                            el.eduProgProfStandFunctionId == item.id\n                        ) !== -1\n                      "}],staticClass:"text-left font-weight-medium py-0 pl-3 pr-3"},_vm._l((_vm.workFunctions.find(el => el.id == item.eduProgProfStandFunctionId).workFunc.workActions),function(action){return _c('div',{key:action.id},[(
                            (action.skills &&
                              action.skills.length > 0)
                          )?_c('v-alert',{staticClass:"ma-0 my-3",attrs:{"dense":"","border":"left","colored-border":"","color":"orange accent-4","elevation":"3"}},[_c('div',{staticClass:"text-subtitle-2 pa-3"},[_c('b',[_vm._v("ТД:")]),_vm._v(" "+_vm._s(action.name)+" "),_c('comment',{attrs:{"comment":_vm.getComment('workAction',action)}})],1),_vm._l((action.skills),function(skill){return _c('v-sheet',{key:skill.id,ref:'skill' + skill.id,refInFor:true,staticClass:"lighten-2 pa-1 ma-1 text-body-2",class:skill.type=='um'?'green':'blue',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","outlined":"","elevation":"3"}},[(skill.type=='um')?_c('b',[_vm._v("У:")]):_c('b',[_vm._v("З:")]),_vm._v(" "+_vm._s(skill.name)+" ")])})],2):_vm._e()],1)}),0)]:_vm._e()],2)}),1)],1)],1)])}),0)])],1)],1),_c('v-col',[_c('v-sheet',{staticClass:"pl-3",attrs:{"outlined":"","elevation":"3"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}})],1),_c('div',{staticStyle:{"height":"calc(100vh - 230px)","overflow-y":"scroll"}},[_c('draggable',_vm._b({staticStyle:{"min-height":"30px","height":"100%"},attrs:{"group":{
            name: 'workFunctions',
            pull: 'clone',
            put: false,
          }},on:{"end":_vm.dragEnd},model:{value:(_vm.filteredRightSide),callback:function ($$v) {_vm.filteredRightSide=$$v},expression:"filteredRightSide"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.filteredRightSide),function(item){return _c('v-sheet',{key:item.id,ref:'workFunction' + item.id,refInFor:true,staticClass:"pa-1 ma-1 text-body-2",staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","elevation":"0"}},[_c('v-alert',{staticClass:"py-1 px-3 ma-0 my-1",class:_vm.showAll &&
                  _vm.usedWorkFunctions.findIndex(
                    (el) => el == item.id
                  ) !== -1
                  ? 'green lighten-5'
                  : '',staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"border":"left","dense":"","colored-border":"","color":"deep-purple accent-4","elevation":"3"}},[_c('div',{staticClass:"text-subtitle-2"},[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",style:('background-color: ' + _vm.$colorsArray[item.profStandIndex] + ' ;'),attrs:{"size":"20"}},'v-avatar',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.profStandName)+" ")]),_c('b',[_vm._v("ТФ:")]),_vm._v(" "+_vm._s(item.workFunc.name)+" "),_c('comment',{attrs:{"comment":_vm.getComment('workFunction',item.workFunc)}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.expandRight(item.id)}}},[(
                        _vm.expandedRight.findIndex(
                          (el) =>
                            el.id == item.id
                        ) !== -1
                      )?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.expandedRight.findIndex(
                (el) =>
                  el.id == item.id
              ) !== -1
            ),expression:"\n              expandedRight.findIndex(\n                (el) =>\n                  el.id == item.id\n              ) !== -1\n            "}],staticClass:"text-left font-weight-medium py-0 pl-3 pr-3"},_vm._l((item.workFunc.workActions),function(action){return _c('div',{key:action.id},[(
                  (action.skills && action.skills.length > 0)
                )?_c('v-alert',{staticClass:"ma-0 my-3",attrs:{"dense":"","border":"left","colored-border":"","color":"orange accent-4","elevation":"3"}},[_c('div',{staticClass:"text-subtitle-2 pa-3"},[_c('b',[_vm._v("ТД:")]),_vm._v(" "+_vm._s(action.name)+" "),_c('comment',{attrs:{"comment":_vm.getComment('workAction',action)}})],1),_vm._l((action.skills),function(skill){return _c('v-sheet',{key:skill.id,ref:'skill' + skill.id,refInFor:true,staticClass:"lighten-2 pa-1 ma-1 text-body-2",class:skill.type=='um'?'green':'blue',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","outlined":"","elevation":"3"}},[(skill.type=='um')?_c('b',[_vm._v("У:")]):_c('b',[_vm._v("З:")]),_vm._v(" "+_vm._s(skill.name)+" ")])})],2):_vm._e()],1)}),0)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }