<template>
  <div  >
      <div class="text-right mt-2 mr-2">
          <export-excel
                  v-if="competencies.length > 0 && disciplines.length > 0"
                  :fields = "json_fields"
                  :data="json_data">
              <v-btn class="text-body-2" color="green lighten-3 ">
                  <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                  Скачать Excel
              </v-btn>
          </export-excel>

      </div>

        <v-simple-table class="py-3" fixed-header height="calc(100vh - 140px)">
          <thead>
            <tr>
                <th class="text-h6 font-weight-bold " style="border: 1px solid;     background-color: #C8E6C9; position: sticky; top:0px; left:0px;z-index:10" rowspan="2">
                    Дисциплины
                </th>
                <th style="border: 1px solid;" :colspan="competencies.length" class=" text-h6 font-weight-bold blue lighten-4" >
                    <div style="position: sticky; left:200px; width:fit-content">Компетенции</div>
                </th>
            </tr>
            <tr>
                <th v-for="competence in competencies" :key="competence.id"  style="border: 1px solid; background-color: #E3F2FD" >
                    <strong>
                        {{ competence.competence.type }} {{ competence.competence.code }}
                    </strong> {{competence.competence.name}}
                </th>
            </tr>
          </thead>
          <tbody>
            <tr class="disciplines" v-for="discipline in disciplines" :key="discipline.id">
                <td style="border: 1px solid;"  class="sticky-col">
                    {{discipline.name}}
                </td>
                <td  style="border: 1px solid;"  align=center v-for="competence in competencies" :key="competence.id">
             
              <v-menu offset-y >
      <template v-slot:activator="{ on, attrs }">
        <div
          color="primary"
          dark
          style="width:fit-content"
          v-bind="attrs"
          v-on="on"
        >
              <v-icon color="green" v-if="checkDisciplineCompetence(discipline,competence)">mdi-check</v-icon><v-icon color="red" v-else>mdi-close</v-icon>
             </div> 
             
      </template><v-sheet color="grey lighten-3"  class="pa-3" style="max-width:300px">
      <b>Дисциплина:</b> {{ discipline.name }}<br>
      <b>Компетенция:</b> <strong>{{ competence.competence.type }} {{ competence.competence.code }}</strong> {{ competence.competence.name }}</v-sheet>
    </v-menu>
            </td></tr>
          </tbody>
        </v-simple-table>
      </div>

</template>
<script>
export default {
  props: ["eduProgId"],
  data() {
    return {
        disciplines:[],
        competencies:[],
      loading: false,

        // json_fields: {
        //     'Дисциплины': 'discipline',
        //     'Компетенции': 'competencie',
        // },
    };
  },
  components:{
  },
    computed:{
        json_data() {
            // let data = []
            let competencies = []
            for(let disc of this.disciplines){
                let object = {}
                for(let comp of this.competencies){
                        object.discipline = disc.name
                    object[comp.competence.type + ' ' + comp.competence.code + ' ' + comp.competence.name] = this.checkDisciplineCompetence(disc,comp) ? 'Да' : ''

                }
                competencies.push(object)
            }
                return competencies
        },
        json_fields() {
            let data = {
                'Дисциплина': 'discipline',
            }

            for (let comp of this.competencies) {
                data[comp.competence.type + ' ' + comp.competence.code + ' ' + comp.competence.name] = comp.competence.type + ' ' + comp.competence.code + ' ' + comp.competence.name
            }

            return data

        },
    },
  methods: {
    onInit: function (panzoomInstance) {
      let that = this;
      panzoomInstance.setTransformOrigin(null);
      panzoomInstance.on("panstart", function () {
        that.isDragging = true;
        // Note: e === instance.
      });
      panzoomInstance.moveTo(0, 0);
      panzoomInstance.zoomTo(0, 0, 2);
      panzoomInstance.on("panend", function () {
        that.isDragging = false;
        // Note: e === instance.
      });
    },

    onTouch: function () {
      // `e` - is current touch event.

      return false; // tells the library to not preventDefault.
    },
    getDisciplineWorkFunctions(discipline){
      return discipline.skills.map(el=>el.workFunctionId)
    },
    getCompetenceWorkFunctions(competence){
      let arr =  competence.workFunctions.map(el=>el.eduProgProfStandFuntion.workFuncId)
      return arr
    },
    compareCustomSkill(discipline,competence){
      let arr1 = discipline.customSkills.map(el=>el.skillId)
      let arr2 = competence.customSkills.map(el=>el.id)
      let intersection = arr1.filter(num => arr2.includes(num));
      return intersection
    },
    checkDisciplineCompetence(discipline,competence){

      let disciplineWorkFunctions = this.getDisciplineWorkFunctions(discipline)
      let competenceWorkFunctions = this.getCompetenceWorkFunctions(competence)
      let intersection = disciplineWorkFunctions.filter(num => competenceWorkFunctions.includes(num));
      if(intersection.length>0){
        return true
      }
      if(this.compareCustomSkill(discipline,competence).length>0){
        return true
      } 
      return false
    },
    getTime(discipline,competenceId){
        let filteredTopics=discipline.disciplineTopics.filter(el=>el.disciplineTopicCompetencies.findIndex(comp=>comp.competenceId==competenceId)!==-1)
        
        let vol=0
        for(let  topic of filteredTopics){
            vol+=(parseInt(topic.vol)+parseInt(topic.self))
        }
        return vol
    },
    fetchData() {
      this.loading = true;
      
      this.$http
        .get("matrix/matrix", {
          params: {
            eduProgId: this.eduProgId
          },
        })
        .then((response) => {
          this.disciplines = response.data.disciplines;
          this.competencies = response.data.competencies;
        })
        .finally(() => {
          this.loading = false;
        });
        
    }
  },
  mounted(){
    this.fetchData()
  }
};
</script>
<style>
.disciplines:hover > td:first-child{
  background-color: #A5D6A7;
}
.disciplines > td:first-child{
  background-color: #E8F5E9;
}

.wrapperDisabled {
  pointer-events: none;
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  left: 0px;
  z-index:9
}
</style>
